// Common styles
@import 'notability-services-common-frontend/scss/global.scss';
@import 'notability-services-common-frontend/scss/_colors.scss';
@import 'notability-services-common-frontend/scss/featureCard.scss';
@import 'notability-services-common-frontend/scss/highlight.scss';

/* specific to the gallery */
html,
body {
  max-width: 100%;
  font-weight: 300;
  -webkit-tap-highlight-color: transparent;
}

a:hover {
  text-decoration: underline;

  &.no-underline {
    text-decoration: none;
    font-size: 16px;
  }
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}

.spin {
  animation: rotate 2s linear infinite;
}

.action-button-container > svg {
  align-self: center;
}

.transition-color {
  transition: color 0.3s ease-out;
}

.transition-opacity {
  transition: opacity 0.3s ease-out;
}

input.orgTableHeader {
  &::placeholder {
    color: $core-f3;
    opacity: 1;
  }
}

span.orgTableHeader {
  color: $core-f3;
}
