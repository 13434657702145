$core-f1: #171a20;
$core-f2: #444f60;
$core-f3: #7183a0;
$core-f4: #aab5c6;
$core-on-accent: #ffffff;

$core-b1: #ffffff;
$core-b2: #f6f6f8;
$core-b3: #e8ebf0;
$core-b4: #c8cfdb;

$accent-base: #4278ff;
$accent-hover: #3560cc;
$accent-press: #284899;
$accent-container: #ecf2ff;
$accent-container-hover: #d9e4ff;
$accent-container-press: #b3c9ff;
$accent-banner: #6f98ff;

$neutral-base: #444f60;
$neutral-hover: #363f4d;
$neutral-press: #292f3a;
$neutral-container: #e8ebf0;
$neutral-container-hover: #d4dae3;
$neutral-container-press: #b9c2d1;

$secondary-base: #ffa629;
$secondary-hover: #cc8521;
$secondary-press: #996419;
$secondary-container: #fff6ea;
$secondary-container-hover: #ffedd4;
$secondary-container-press: #ffdba9;

$alert-base: #ff577e;
$alert-hover: #cc4665;
$alert-press: #99344c;
$alert-container: #ffeef2;
$alert-container-hover: #ffdde5;
$alert-container-press: #ffbccb;

$dark-secondary-press: #ffca7f;

@mixin thumb-shadow {
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.1);
}

@mixin card {
  @include thumb-shadow;
  border-radius: 12px;
  overflow: hidden;
}
