@import 'notability-services-common-frontend/scss/_colors.scss';

svg.highlight {
  fill: $dark-secondary-press;
  grid-area: 1 / 1 / 2 / 2;
  width: 100%;
  height: min(80%, 40px);
  z-index: 0;
  align-self: center;
}

.grid-container {
  display: inline-grid;
  grid-template-columns: min-content;
  grid-auto-rows: 0fr;
}

.text-container {
  grid-area: 1 / 1 / 2 / 2;
  white-space: nowrap;
  z-index: 1;
}
