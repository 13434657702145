@import 'squareImage.scss';

$a-color: #0070f3;

html,
body {
  padding: 0;
  margin: 0;
  font-size: 16px;
}

html,
body,
input,
textarea {
  font-family: var(--font-proxima), -apple-system, Segoe UI, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans,
    Helvetica Neue, sans-serif;
}

img {
  max-width: 100%;
  display: block;
}

* {
  box-sizing: border-box;
}

a {
  color: $a-color;
  text-decoration: none;

  &:hover {
    text-decoration: none;
  }

  cursor: pointer;
}

span.fake-link {
  color: $a-color;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }

  cursor: pointer;
}
